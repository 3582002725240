<template>
  <v-container class="mt-5 main-content" >
     
      <div class="Title" >
       <h1 class="text-h5">  Q&A</h1>
      </div>
      <v-card   class="my-10 mx-1 px-4 pb-2 pt-3">
      <section class="my-1">

        <h6 class="text-h4 text-left mt-1 mb-3">目次</h6>
          <ul :class="$vuetify.breakpoint.xs?'body-1 text-left ' : 'ml-10 body-1 text-left'">
            <!-- <li><a href="#agreement">契約情報はどのように変更すればいいですか？</a></li>
            <li><a href="#password_change">パスワードの変更はどのように行えばよいですか？</a></li>
            <li><a href="#create_navi">ナビゲーションはどのように作成するんですか？</a></li> -->
            <li v-for="item in questions" :key="item.id"><a :href="'#'+item.id" >{{item.text}}</a></li>
           
            <!-- <li><a href="#"></a></li> -->
          </ul>

      </section>
      </v-card>
     <v-card   class="my-10 mx-1 px-4 pb-2 pt-3">

      <section >

        <h6 id="start_question" class="text-h3 text-left mt-2 my-8" >Questions</h6>
          <div class="body-1">ここにない質問は本サイトの <a  href="/support">お問合せページ<v-icon small color="primary">{{icons.openInNew}}</v-icon></a>でのご質問をお願いします。</div>
          <ul  class="my-4" style="border-top:solid 1px gray;">


            <li id="agreement" class="text-h6 my-10 text-left question ">
             <p class="ma-0 pa-0 font-weight-bold">契約情報はどのように変更すればいいですか？</p>
             <div class="body-1 my-3">
                契約プランは以下のようになっています。<br>
                <ul class="my-3">
                  <li class="my-3"> <span class="font-weight-bold">Freeプラン</span>:無料のプラン <br>
                    ナビが1個、コンテンツ系が各20MB、URLが20個までアップロードができます。
                  </li>
                  <li class="my-3"> <span class="font-weight-bold">Standardプラン</span>:有料のプラン <br>
                    ナビが5個、コンテンツ系が各1GB、URLが40個までアップロードができます。
                  </li>
                  <li class="my-3"> <span class="font-weight-bold">Enterprizeプラン</span>:有料のプラン <br>
                    ナビが20個、コンテンツ系が各5GB、URLが60個までアップロードができます。
                  </li>
                </ul>
                契約の変更は個別に対応いたしますので、本サイトの<a  href="/support">お問合せ<v-icon small color="primary">{{icons.openInNew}}</v-icon></a>からご連絡をお願いします。
             </div>
            </li>

            <li id="password_change" class="text-h6 my-10 text-left question">
             <p class="ma-0 pa-0 font-weight-bold">パスワードの変更はどのように行えばよいですか？</p>
             <div class="body-1 my-3">
                パスワードの変更は本サイトの「アカウント」-> 「パスワードの変更」から行えます。
             </div>
            </li>


             <li id="create_navi" class="text-h6 my-10 text-left question">
             <p class="ma-0 pa-0 font-weight-bold">ナビゲーションはどのように作成すればよいですか？</p>
             <div class="body-1 my-3">
                本サイトではナビゲーションの作成は行えません。本サイトで行えることはナビゲーションの確認、サムネイル、名前、説明、公開設定の変更です。<br>
                ナビゲーションの作成は<a  href="https://apps.apple.com/jp/app/%E3%83%8A%E3%83%AC%E3%82%B3%E3%83%A0vr/id1579268145">こちら<v-icon small color="primary">{{icons.openInNew}}</v-icon></a>のIOSアプリから作成して下さい。
             </div>
            </li>


            
            <li id="place_text_picture" class="text-h6 my-10 text-left question">
              <p class="ma-0 pa-0 font-weight-bold">アンカーを配置することはできましたが，体験版では，テキストや写真などを配置することはできないのでしょうか?</p>
              <div class="body-1 my-3">
                現在、テキストや写真などのコンテンツの配置機能は開発中でございます。<br>
                恐れ入りますがアップデートをお待ちいただけますと幸いです。<br>
                画像の配置できます。テキストは画像にして配置してください。（藤本）
              </div>
            </li> 


            <li id="limit_anchor" class="text-h6 my-10 text-left question">
              <p class="ma-0 pa-0 font-weight-bold">容量制限によるものか，アンカーが多いと保存されないのでしょうか?</p>
              <div class="body-1 my-3">
                  アンカーの配置できる数には制限がございます。<br>
                  ※ナレコムVRのシステムに利用しているAzure Spatial Anchors の同時検索の制限が35個までのため <br>
                  またアンカーの設置は空間を認識して設置する仕様になっており、環境によって、保存や検索に失敗する場合があります。<br>
                  その場合は以下をお試しください。
                  <ul class="my-3">
                    <li class="my-3">場所を変更する</li>
                    <li class="my-3">設置する環境に特徴となるような物を設置する</li>
                  </ul>
                  Azure Spatial Anchorsの詳細について知りたい方は
                  <a href="https://docs.microsoft.com/ja-jp/azure/spatial-anchors/spatial-anchor-faq"> Azure Spatial Anchors についてよく寄せられる質問<v-icon small color="primary">{{icons.openInNew}}</v-icon></a>
                  をご参照ください。

              </div>
            </li>

            <li id="limit_anchor2" class="text-h6 my-10 text-left question">
              <p class="ma-0 pa-0 font-weight-bold">配置できるアンカーの個数に制限はありますか？</p>
              <div class="body-1 my-3">
                 35個まで配置可能です。（藤本）
              </div>
            </li> 

            <li id="limit_content" class="text-h6 my-10 text-left question">
              <p class="ma-0 pa-0 font-weight-bold">コンテンツに制限はありますか？</p>
              <div class="body-1 my-3">
                  制限はありませんが、負荷がかかるとアプリが落ちる場合がございます。<br>   
                  HoloLens2の詳細について知りたい方は
                  <a href="https://docs.microsoft.com/ja-jp/hololens/hololens2-hardware"> HoloLens2について<v-icon small color="primary">{{icons.openInNew}}</v-icon></a>
                  をご参照ください。
              </div>
            </li>

            <li id="how_errpr_anchor" class="text-h6 my-10 text-left question">
              <p class="ma-0 pa-0 font-weight-bold">アンカーの誤差はどの程度ありますか？</p>
              <div class="body-1 my-3">
                環境に依ります。ルームスケールで数センチ程度です。（藤本）
              </div>
            </li>

            <li id="case" class="text-h6 my-10 text-left question">
              <p class="ma-0 pa-0 font-weight-bold">製造業の事例はどの程度ありますか？</p>
              <div class="body-1 my-3">
                  公開できているのは立教大学様やMTI様になります。<br>
                  製造業様関連ですと若干違いますが、データセンターの設備を保有する御客様で人の誘導とマニュアル表示のユースケースで活用いただいております。
              </div>
            </li>


            <li id="most_case" class="text-h6 my-10 text-left question">
              <p class="ma-0 pa-0 font-weight-bold">もっとも多いユースケースはどのようなものですか？</p>
              <div class="body-1 my-3">
                  現在お引き合い頂いているもので多いのが、会社案内や工場案内といった人の誘導と作業支援で活用いただくケースとなります。
              </div>
            </li> 


            <li id="contract_unit" class="text-h6 my-10 text-left question">
              <p class="ma-0 pa-0 font-weight-bold">契約単位は月単位ですか、年単位ですか？</p>
              <div class="body-1 my-3">
                  3ヶ月になります。
              </div>
            </li>


            <li id="how_long_navi" class="text-h6 my-10 text-left question">
              <p class="ma-0 pa-0 font-weight-bold">ナビゲーションが作れる距離はどれくらいありますか？</p>
              <div class="body-1 my-3">
                  空間マッピング可能な領域だけ作れます。50m<sup>2</sup>で試したことはあります。<br>
                  距離が長くなると誤差も大きくなりますので注意してください。（藤本）
              </div>
            </li>

            <li id="limit_content_per_navi" class="text-h6 my-10 text-left question">
              <p class="ma-0 pa-0 font-weight-bold">ひとつのナビゲーションに掲載できるコンテンツ数は何個ですか？</p>
              <div class="body-1 my-3">
                  制限はありませんが、負荷をかけるとアプリが落ちます。<br>
                  ハードウェアのメモリに依存するかと思います。（藤本）
              </div>
            </li>

            <li id="narekomuvr_strong" class="text-h6 my-10 text-left question">
              <p class="ma-0 pa-0 font-weight-bold">ナレコムVRの強みや他社との差別化要素はなんですか？</p>
              <div class="body-1 my-3">
                  我々が考える特徴は以下になります。 <br>
                  コンテンツの配置、誘導、作って表示できる、すぐに（藤本）
              </div>
            </li>

            <li id="loadmap" class="text-h6 my-10 text-left question">
              <p class="ma-0 pa-0 font-weight-bold">共有機能以降の今後のロードマップはありますか？</p>
              <div class="body-1 my-3">
                <ol class="my-3">
                  <li class="my-3">クオリティをあげる</li>
                  <li class="my-3">無料コンテンツの提供</li>
                  <li class="my-3">ユースケースに沿ったデモ動画の公開</li>
                </ol>

                  お客様の要望により以下の機能拡充も検討しています。
                  <ul class="my-3">
                    <li class="my-3">Remote Renderingを用いた大容量コンテンツの表示</li>
                    <li class="my-3">PDFの検索</li>
                    <li class="my-3">GLBアニメーション対応</li>
                  </ul>
              </div>
            </li>

            <li id="how_many_share_content" class="text-h6 my-10 text-left question">
              <p class="ma-0 pa-0 font-weight-bold">コンテンツ共有のアップデートがあるとのことですが、何台くらいのHoloLens、スマホと共有できる予定ですか？</p>
              <div class="body-1 my-3">
                  試していない。同じナビを表示するだけなので、何台でもいけるはず。（藤本）
              </div>
            </li>

            <li id="devices" class="text-h6 my-10 text-left question">
              <p class="ma-0 pa-0 font-weight-bold">Android、iOSの対応機種は？</p>
              <div class="body-1 my-3">
                  <span class="font-weight-bold">【iOS】 </span>
                    <div class="ml-6 mt-2 mb-4"> 
                      対応OS
                      <!-- <ul class="my-3"> -->
                      <li class="my-1 ml-7 mb-3">iOS13.2.1以降</li>
                      対応機種
                      <li class="my-1 ml-7">iPhone</li>
                      <div class="ml-7">8 Plus、X/Xr/Xs/Xs Max、11/11 Pro/11 Pro Max、12/12 mini/12 Pro/12 Pro Max</div>
                      <li class="my-1 ml-7">iPad</li>
                      <div class="ml-7 mb-2">6/7/8、Pro 11インチ/Pro 12.9インチ 第3~5世代、Air 第3･4世代、mini 5</div>
                        iOSアプリのダウンロードは<a href="https://apps.apple.com/jp/app/%E3%83%8A%E3%83%AC%E3%82%B3%E3%83%A0vr/id1579268145">こちら</a>から
                    </div>
                  

                    <span class="font-weight-bold">【Android】</span>
                     <div class="ml-6 mt-2 mb-4"> 
                    対応環境
                      <li class="my-1 ml-7 mb-3">Android8.0以降</li>
                      <li class="my-1 ml-7 mb-3">ARCore対応端末 [<a href="https://developers.google.com/ar/devices">参考</a>] </li>
                    Androidアプリのダウンロードは<a href="https://play.google.com/store/apps/details?id=com.DefaultCompany.NarekomuVR2Mobile&hl=en_US&gl=US">こちら</a>から
                     </div>
              </div>
            </li>

            <li id="limit_upload_portal" class="text-h6 my-10 text-left question">
              <p class="ma-0 pa-0 font-weight-bold">ポータルにコンテンツをアップロードする際の容量制限はどれくらいですか？(画像、PDFなどのコンテンツごとに回答いただけるとありがたいです)</p>
              <div class="body-1 my-3">
                  制限はありませんが、表示する際に負荷がかかりアプリが落ちることがございますので、20MB以内を推奨しています。<br>
                  全体の容量としては5GBが上限となります。
              </div>
            </li>


            <li id="func_narekomuvr" class="text-h6 my-10 text-left question">
              <p class="ma-0 pa-0 font-weight-bold">ストアにあがっているナレコムVRはどのような機能が利用できるますか？</p>
              <div class="body-1 my-3">
                  コンテンツの配置（3Dモデル、画像、PDF）およびナビゲーションの作成表示ができます。
              </div>
            </li>

            <!-- サンプル -->
            <!-- <li id="create_navi" class="text-h6 my-10 text-left question">
              <p class="ma-0 pa-0 font-weight-bold">質問内容</p>
              <div class="body-1 my-3">
                  回答
              </div>
            </li> -->


          </ul>

      </section>

      </v-card>

  </v-container>
</template>

<script>
import { mdiOpenInNew } from '@mdi/js';


  export default {
    name: 'QA',
    data: () => ({
      icons:{
        "openInNew":mdiOpenInNew
      },
     questions:[]

    }),

    mounted(){
      //質問のリンク作成用
      //idを一個ずらしている
      let q = document.getElementsByClassName("question");
      let tmpId = "start_question"
      for(let i = 0; i < q.length; i++){
        let tmpList = {id:"",text:""};
        tmpList.id = tmpId
        tmpList.text = q[i].getElementsByTagName("p")[0].innerHTML;
        this.questions.push(tmpList);
        tmpId = q[i].id;

      }
      console.log(this.questions)

    }
  }
</script>


<style scoped>

  a {
     text-decoration: none;
  }

  

  .Title > h1 {
    color: gray;
    font-weight: 400;
    border-bottom: 1px solid gray;
  }

  .subtitle{
    font-weight:200;
  }

  /* .container{
    width:100%;
    max-width:800px;
  } */

</style>
